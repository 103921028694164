:root {
    --panel-title: #0A3C54;
    --panel-title2: #062343;
    --panel-title3:#8C1F28;
    --panel-title1:#733F2D;
    --panel-content: #299CDC;
    --panel-content2: #5A88C1;
    --panel-content3: #F2CAB3;
    --panel-content1: #D9A38F;
    --panel-content-selected: rgb(4, 41, 64,0.3);
    --panel-main: rgba(0,0,0,0.05);
    --panel-container: #F5F5F5;
    --white: #FFF;
    --black: #000;
    --grey-light:#CCC;
    --grey: #AAA;
    --grey-dark: #999;
    --grey-dark-dark: #666;
    --transition-button: 0.6s;
}

html {
    overflow: hidden;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 100%;
}

div {
    margin: 0;
    padding: 0;
}


/* #bp3d-js-app {
    overflow: hidden;
} */

#bp3djs-viewer2d,
#bp3djs-viewer3d {
    position: absolute;
    width: 500px; /*100vw;*/
    height: 500px; /*100vh;*/
    top: 0;
    left: 0;
}

#bp3djs-controls {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

/**
    The below css enables color picker for quicksettings
    https://github.com/bit101/quicksettings/issues/57
*/
.qs_color {
    position: absolute;
    left: 5px;
    visibility: hidden;
}

.qs_main {
    background-color: var(--panel-main);
    text-align: left;
    position: absolute;
    font: 12px sans-serif;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
    user-select: none;
    -webkit-user-select: none;
    color: var(--black);
    border: none;

}

.qs_content {
    text-align: center;
    background-color: var(--panel-content);
    overflow-y: auto;
}

.qs_title_bar {
    background-color: var(--panel-title);
    text-align: center;
    border-radius: 10px 10px 0 0;
    font-size: 14px;
    user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    font-weight: bold;
    padding: 10px;
    color: var(--white);
}

.qs_title_section_custom h4 {
    margin: 0;
    padding: 0;
}
.qs_container_title_section_custom {
    margin: 0;
    padding: 0;
    border-top: 3px solid var(--panel-title);
    border-bottom: 3px solid var(--panel-title);
    background-color: var(--panel-container);
}

.qs_container {
    padding: 10px 0;
    background-color: var(--panel-content);
    border: none;
    position: relative
}

.qs_container_selected {
    border: none;
    background-color: var(--panel-content-selected);
}

.qs_range {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    height: 17px;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.qs_range:focus {
    outline: none;
    border: none
}

.qs_range::-webkit-slider-runnable-track {
    width: 100%;
    height: 15px;
    cursor: pointer;
    background: var(--grey-light);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.qs_range:focus::-webkit-slider-runnable-track {
    background: var(--grey-light)
}

.qs_range::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background: var(--grey-dark);
    cursor: pointer;
    margin-top: 0
}

.qs_range::-moz-range-track {
    width: 100%;
    height: 15px;
    cursor: pointer;
    background: var(--grey-light);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.qs_range::-moz-range-thumb {
    height: 15px;
    width: 15px;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background: var(--grey-dark);
    cursor: pointer
}

.qs_range::-ms-track {
    width: 100%;
    height: 15px;
    cursor: pointer;
    visibility: hidden;
    background: transparent
}

.qs_range::-ms-thumb {
    height: 15px;
    width: 15px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background: var(--grey-dark);
    cursor: pointer;
    border: none
}

.qs_range::-ms-fill-lower {
    background: var(--grey-light);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.qs_range:focus::-ms-fill-lower {
    background: var(--grey-light)
}

.qs_range::-ms-fill-upper {
    background: var(--grey-light);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.qs_range:focus::-ms-fill-upper {
    background: var(--grey-light)
}

.qs_button {
    background-color: var(--panel-title);
    border-radius: 5px;
    border: 2px solid transparent;
    margin: auto;
    color: var(--white);
    font: 16px sans-serif;
    transition: 0.4s;
}
.qs_button:hover{
    border-radius: 5px;
    border: 2px solid white;
}

.qs_button:active {
    background-color: var(--white);
    border: 1px solid var(--grey);
    color: var(--white);
}

.qs_button:focus {
    border: 1px solid var(--grey);
    outline: none
}

.qs_checkbox {
    cursor: pointer
}

.qs_checkbox input {
    position: absolute;
    left: -99999px
}

.qs_checkbox span {
    height: 16px;
    width: 100%;
    display: block;
    text-indent: 20px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAALklEQVQ4T2OcOXPmfwYKACPIgLS0NLKMmDVrFsOoAaNhMJoOGBioFwZkZUWoJgApdFaxjUM1YwAAAABJRU5ErkJggg==') no-repeat
}

.qs_checkbox input:checked + span {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAvElEQVQ4T63Tyw2EIBAA0OFKBxBL40wDRovAUACcKc1IB1zZDAkG18GYZTmSmafzgTnnMgwchoDWGlJKheGcP3JtnPceCqCUAmttSZznuYtgchsXQrgC+77DNE0kUpPbmBOoJaBOIVQylnqWgAAeKhDve/AN+EaklJBzhhgjWRoJVGTbNjiOowAIret6a+4jYIwpX8aDwLIs74C2D0IIYIyVP6Gm898m9kbVm85ljHUTf16k4VUefkwDrxk+zoUEwCt0GbUAAAAASUVORK5CYII=') no-repeat
}

.qs_checkbox_label {
    position: absolute;
    top: 7px;
    left: 30px
}

.qs_label {
    margin-bottom: 3px;
    user-select: none;
    -webkit-user-select: none;
    cursor: default;
    font: 12px sans-serif
}

.qs_text_input {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    padding: 0 0 0 5px;
    height: 24px;
    border: 1px inset var(--white);
    background-color: var(--white);
    color: var(--black);
    font-size: 16px
}

.qs_text_input:focus {
    outline: none;
    background: var(--white);
    border: 1px inset var(--white)
}

.qs_select {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAp0lEQVRIS+2SsQ3FIAwF7RVYhA5mgQFhFuhYhJKWL0eKxI8SGylKZ0p4+OBsHGNM+HChAiS7qkgyBKrovaLeOxhjbgtxZ+cFtgelFMg5QwgBvPd/EO5sDbKAlBLUWo/8CjmL075zDmKMj6rEKbpCqBL9aqc4ZUQAhVbInBMQUXz5Vg/WfxOktXZsWWtZLds9uIqlqaH1NFV3jdhSJA47E1CAaE8ViYp+wGiWMZ/T+cgAAAAASUVORK5CYII=') no-repeat right var(--white);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: var(--black);
    width: 100%;
    height: 24px;
    border: 1px solid var(--grey);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 0 5px;
    font-size: 16px
}

.qs_select option {
    font-size: 16px
}

.qs_select::-ms-expand {
    display: none
}

.qs_select:focus {
    outline: none
}

.qs_number {
    height: 24px
}

.qs_image {
    width: 100%
}

.qs_progress {
    width: 100%;
    height: 15px;
    background-color: var(--grey-light);
    border: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.qs_progress_value {
    height: 100%;
    background-color: var(--grey-dark)
}

.qs_textarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    resize: vertical;
    width: 100%;
    padding: 3px 5px;
    border: 1px inset var(--white);
    background-color: var(--white);
    color: var(--black);
    font-size: 16px
}

.qs_textarea:focus {
    outline: none;
    background: var(--white);
    border: 1px inset var(--white)
}

.qs_color {
    position: absolute;
    left: -999999px
}

.qs_color_label {
    width: 100%;
    height: 20px;
    display: block;
    border: 1px solid var(--grey);
    cursor: pointer;
    padding: 0 0 0 5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.qs_file_chooser {
    position: absolute;
    left: -999999px
}
.qs_label b {
    display: none;
}

.qs_file_chooser_label {
    justify-self: center;
    background-color: var(--panel-title);
    border: 2px solid transparent;
    font-size: 16px;
    border-radius: 5px;
    color: var(--white);
    width: 80%;
    display: block;
    cursor: pointer;
    margin: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: 0.4s;
}

.qs_file_chooser_label:hover {
    border: 2px solid white;
    border-radius: 5px;
}
.qs_container_paragraph_custom p {
    width: 100%;
    padding: 10px 0;
    margin: 15px 0 0 0;
    background-color: var(--panel-title);
}
.qs_paragraph_value_custom {
    width: 100%;
    font-size: 14px;
    color: var(--white);
}
